<template>
  <quizzes-component :module-data="quizModule" class="mt-3"></quizzes-component>
</template>

<script>
import QuizzesComponent from "@/web/components/QuizzesComponent";
import { mapActions, mapGetters } from "vuex";
import Constants from "@/web/constants";

export default {
  name: "Quizzes",

  components: {QuizzesComponent},

  mounted() {
    this.loadQuizzes(this.componentId);
  },

  computed: {
    ...mapGetters("quizzes", ["getAllFromComponent"]),
    ...mapGetters(["moduleByComponentId", "inboxEnabled"]),

    Constants: () => Constants,

    componentId() {
      return this.$route.params.componentId;
    },

    quizModule() {
      return this.moduleByComponentId(Number(this.componentId));
    },
  },

  methods: {
    ...mapActions("quizzes", ["loadQuizzes"]),
  },
};
</script>

<style scoped></style>
